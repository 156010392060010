<template>
    <el-main>
        <div style="margin-bottom: 10px">
            <router-link to="/repair/goods/addType"><el-button type="primary" size="small">添加分类</el-button></router-link>
        </div>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="商品分类：">
                <el-input size="small" v-model="name" placeholder="请输入搜索分类"></el-input>
            </el-form-item>
            <el-form-item label="是否显示：">
                <el-select v-model="is_show" @change="showValChange" size="small">
                    <el-option v-for="item in showOptions" :key="item.value" :label="item.label"
                        :value="item.value"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button type="primary" @click="searchType" size="small">搜索</el-button>
                <el-button class="boderBlue" size="small" @click="exportGoodsClass">导出</el-button>
                <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="goodsClass" row-key="id" default-expand-all :header-cell-style="{ 'background-color': '#F8F9FA' }"
            :tree-props="{ children: '_child', hasChildren: 'hasChildren' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column prop="name" label="分类名称" align="center">
                <template v-slot="{ row }">
                    <span :style="{ 'font-weight': row.grade == '一级' ? 'bold' : '' }">{{ row.name }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="grade" label="分类等级" align="center">
                <template v-slot="{ row }">
                    <span :style="{ 'font-weight': row.grade == '一级' ? 'bold' : '' }">{{ row.grade }}</span>
                </template>
            </el-table-column>
            <el-table-column prop="id" label="分类ID" align="center"></el-table-column>
            <el-table-column prop="address" label="组内权重" width="120">
                <template #header>
                    <div class="edit">
                        <span>组内权重</span>
                        <i class="el-icon-edit-outline"></i>
                    </div>
                </template>
                <template slot-scope="scope">
                    <el-input size="small" type="Number" :min="0" @change="editChange(scope.row)"
                        v-model="scope.row.sort"></el-input>
                </template>
            </el-table-column>
            <!--<el-table-column prop="address" label="商品数量"></el-table-column>-->
            <el-table-column prop="picture" label="图片" align="center">
                <template slot-scope="scope">
                    <el-image style="width: 60px; height: 60px"
                        :src="scope.row.picture ? scope.row.picture : require('@/assets/default-img.png')"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="是否显示" align="center">
                <template slot-scope="scope">
                    <el-switch v-model="scope.row.is_show" :active-value="1" :inactive-value="0"
                        @change="editChange(scope.row)"></el-switch>
                </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <!--<el-button type="text" size="small">添加子分类</el-button>-->
                    <!--<el-button type="text" size="small" @click="dialogVisible = true">转移商品</el-button>-->
                    <el-button @click="edit(scope.row)" type="text" size="small">编辑</el-button>
                    <el-button @click="remove(scope.row)" type="text" size="small">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="转移商品" :visible.sync="dialogVisible" width="40%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="原商品分类">男装</el-form-item>
                <el-form-item label="活动区域">
                    <el-select placeholder="请选择商品分类">
                        <el-option label="区域一" value="shanghai"></el-option>
                        <el-option label="区域二" value="beijing"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
    </el-main>
</template>

<script>
import config from '@/util/config';
const industryName = '/Community'; //行业版本接口名称（用于更换重复接口）
export default {
    data () {
        return {
            showOptions: [
                {
                    value: 0,
                    label: '全部',
                },
                {
                    value: 1,
                    label: '否',
                },
                {
                    value: 2,
                    label: '是',
                },
            ],
            is_show: 0,
            name: '',
            goodsClass: [],
            dialogVisible: false,
            form: {
                page: 1,
                rows: 10
            }
        };
    },
    created () {
        this.getList();
    },
    methods: {
        getList () {
            this.$axios
                .post(industryName + this.$api.goods.goodsClass, {
                    is_show: this.is_show,
                    name: this.name,
                })
                .then(res => {
                    if (res.code == 0) {
                        let list = res.result;
                        for (let i in list) {
                            list[i].grade = '一级';
                            for (let y in list[i]._child) {
                                list[i]._child[y].grade = '二级';
                                for (let k in list[i]._child[y]._child) {
                                    list[i]._child[y]._child[k].grade = '三级';
                                }
                            }
                        }
                        this.goodsClass = list;
                    }
                });
        },
        exportGoodsClass () {
            this.$axios.post(this.$api.goods.exportGoodsClass).then(res => {
                if (res.code == 0) {
                    let path = config.baseurl + '/' + res.result;
                    let a = document.createElement('a');
                    a.href = path;
                    a.id = 'download';
                    document.body.appendChild(a);
                    a.click();
                    let aDom = document.getElementById('download');
                    document.body.removeChild(aDom);
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        editChange (row) {
            this.$axios
                .post(industryName + this.$api.goods.editType, {
                    id: row.id,
                    parent_id: row.parent_id,
                    name: row.name,
                    picture: row.picture,
                    sort: row.sort,
                    is_show: row.is_show,
                    type: row.type,
                })
                .then(res => {
                    if (res.code == 0) {
                        this.$store.dispatch('goods/getTypeList', {
                            industryName,
                            is_show: this.is_show,
                            name: this.name,
                        });
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        showValChange (val) {
            this.is_show = Number(val);
        },
        //搜索分类
        searchType () {
            this.$store.dispatch('goods/getTypeList', {
                industryName,
                is_show: this.is_show,
                name: this.name,
            });
        },
        cancelSearch () {
            this.name = '';
            this.is_show = 0;
            this.$store.dispatch('goods/getTypeList', {
                industryName,
                is_show: this.is_show,
                name: this.name,
            });
        },
        //编辑分类
        edit (row) {
            this.$router.push({
                path: '/repair/goods/editType',
                query: {
                    info: JSON.stringify(row),
                },
            });
        },
        //删除分类
        remove (row) {
            this.$confirm('确认删除此商品分类？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.$axios
                    .post(industryName + this.$api.goods.delType, {
                        id: row.id,
                    })
                    .then(res => {
                        if (res.code == 0) {
                            this.getList();
                            this.$message({
                                message: '删除成功',
                                type: 'success',
                            });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;

    .el-table {
        flex: 1;

        /deep/ .el-input__inner {
            border-color: #fff;
        }

        /deep/ .el-input__inner:focus {
            border-color: #409eff;
        }

        border-top: 1px solid #ebeef5;
        border-left: 1px solid #ebeef5;
        border-right: 1px solid #ebeef5;
    }

    .edit {
        display: flex;
        align-items: center;

        span {
            margin-right: 5px;
        }

        i {
            font-size: 17px;
        }
    }

    .move .item {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-bottom: 20px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    // 这是点击后的旋转角度
    transform: rotate(0deg);
}

/deep/.el-table__expand-icon {
    .el-icon-arrow-right:before {
        //这是收起图标
        content: '\e723';
        font-size: 16px;
    }
}

/deep/ .el-table__expand-icon--expanded {
    .el-icon-arrow-right:before {
        // 这是展开图标
        content: '\e722';
        font-size: 16px;
    }
}
</style>
